body {
  background-color: #111 !important;
  padding-top:57px;
}

canvas {
  margin: 0;
  padding: 0;
}

.zulu-clock {
  background: #222;
  border: 1px solid #111;
  color: white;
  padding: 5px 10px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.0;
  
  margin-right: 10px;
  margin-left: 75px;
  
}

.flex {
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
}

.config-title {
  color: white;
}

.profile-body {
  color: white;
}

.status-body {
  color: white;
}

.nav-logo {
  color: #eee;
}

.edct-table {
  border: 1px solid #eee;
}

.edct-available {
  background-color: #029962 !important;
}

.edct-used {
  background-color: #db5000 !important;
}

.edct-unavailable {
  background-color: #a30000 !important;
}

.edct-table th td {
  border: 1px solid #eee;
  padding: 5px 40px;
}

.page-header {
  margin: 10px;
  border-bottom: 1px solid #eee;
}

.edct-header {
  color:white;
  margin-top: 20px;
}

.edct-ladder {
  border-collapse: collapse;
  border-spacing: 0;
  color: white;
  user-select: none;
}

.edct-ladder td {
  border-top: none;
  border-bottom: none;
  text-align: center;
}

.edct-ladder-middle {
  border-bottom:1px solid #eee !important;
  border-top: 1px solid #eee !important;
  /*border-right: 1px solid #eee;*/
  padding: 0.5em !important;
}

.edct-ladder-tick {
  padding: 0 !important;
}

.tmu-container {
  margin-bottom: 10px;
}

.tmu-ladder {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  margin: auto;
  margin-top: 0;
  padding: 0;
  position: relative;
  top: -7px;
}

.tmu-ladder tr td {
  padding: 0;
  margin: 0;
}

.tmu-ladder td {
  padding-top: 10px;
}

.tmu-ladder td {
  color: white;
}

.current_time_baseline {
  padding: 0;
  width: 90px;
  margin: 0 auto;
  border-top: 1px solid #eee;
  position: relative;
  top: -7px;
}

/* .tmu-ladder tr:nth-child(1) {
  border-top: 1px solid #eee;
} */

.bottom_box {
  display: inline-block;
  position:relative;
  top:4px;
  padding: 0;
  width: 30px;
  height: 20px;
  border: 2px solid #eee;
  cursor: pointer;
}

tr.bottom_row {
  margin: 0;
}

.timeline_num {
  position: relative;
  top: -4px;
}

.bottom_status {
  color: greenyellow;
}

/* .zulu-clock {
  color: white;
  margin-right: 10px;
  margin-left: 75px;
} */

.navbar-title {
  color: greenyellow;
  margin-left: 75px;
}

.local-clock {
  color: gray;
  margin-left: 10px;
}

.mono-font {
  font-family: 'Roboto Mono', monospace;
}

.stream-cfg {
  
  color: black;
}

.stream-cfg .select2-selection--multiple {
  background-color: rgba(255, 255, 255, 0);  
}

.stream-cfg .select2-container--disabled .select2-selection--multiple {
  background-color: rgba(255, 255, 255, 0);  
}

.stream-cfg .select2-selection__choice {
  background-color: #111;
  
}

h4, h5, h6 { 
  color: white;
}

/*.TimelineControlsCont input[type="checkbox"] {
  width: 20px;
  height: 20px;
}*/

.accordion-body {background-color:black;}
.accordion-button {background-color:gray; color:white;}
.accordion-button:not(.collapsed) { background-color: gray;
color: white;}